<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.codigo"
        class="col-12 col-md-2"
        :label="$t('modulos.classificacao_fiscal.formulario.codigo')"
        obrigatorio
        mascara="####.##.##.##"
        trim
      />
      <input-text
        v-model="form.aliquotaIpi"
        class="col-12 col-md-2"
        :label="$t('modulos.classificacao_fiscal.formulario.aliquotaIpi')"
        obrigatorio
        type="number"
        :max="100"
        :min="0"
        :regras-personalizadas="regraAliquotaIpiMaxima()"
      />
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-8"
        :label="$t('modulos.classificacao_fiscal.formulario.descricao')"
        :max="200"
        :min="1"
        obrigatorio
        trim
      />
      <input-textarea
        v-model="form.descricaoCompleta"
        class="col-12"
        :max="2000"
        :label="
          $t('modulos.classificacao_fiscal.formulario.descricao_completa')
        "
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ClassificacaoFiscalService from '@common/services/cadastros/ClassificacaoFiscalService.js';
import { ClassificacaoFiscalModel } from '@common/models/cadastros/ClassificacaoFiscalModel.js';
import helpers from '@common/utils/helpers';
export default {
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new ClassificacaoFiscalModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id)
        return this.$t('modulos.classificacao_fiscal.titulos.editar');
      return this.$t('modulos.classificacao_fiscal.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this,'ClassificacaoFiscal', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'ClassificacaoFiscal', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ClassificacaoFiscalService.buscar(this.id)
        .then((res) => {
          this.form = new ClassificacaoFiscalModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.classificacao_fiscal.erros.id_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.$nextTick().then(
            () => (this.form.codigo = parseFloat(this.form.codigo))
          );
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    regraAliquotaIpiMaxima: function () {
      if (!this.form.aliquotaIpi) return [];

      const regraValor = (v) => {
        if (parseFloat(v) > 100 || parseFloat(v) < 0)
          return this.$t('geral.erros.aliquotaDeveEstarEntre0a100');

        const valor = `${v}`;
        if (valor.split('.').length > 1) {
          const decimalValue = valor.split('.')[1];
          if (decimalValue.length > 2)
            return this.$t('inputs.rules.maxBetweenDecimalValue', {
              char: 3,
              decimal: 2,
            });
        }
        return true;
      };
      return [regraValor];
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.form.codigo = this.form.codigo.replaceAll('.', '');
      ClassificacaoFiscalService.salvar(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.classificacao_fiscal.cadastro_sucesso`)
          );
          this.$router.push({ name: 'classificacao-fiscal' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'classificacao-fiscal' });
      });
    },
  },
};
</script>
